import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Header from "../components/Header/Header";
import Product from "../pages/Products";
import Footer from "../components/Footer/Footer";
import Contact from "../pages/Contact";
import SideMenu from "../components/Sidemenu/Sidemenu";
import { TabProvider } from "../context/TabContext";
import ScrollToTop from "../components/ScrollTop/ScrollTop";

// ConditionalHeader Component
const ConditionalHeader = ({
  scrollActive,
  toggleMenu,
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const location = useLocation();

  useEffect(() => {
    const body = document.querySelector("body");
    if (location.pathname === "/products") {
      body.classList.add("bg-gray-100");
    } else {
      body.classList.remove("bg-gray-100");
    }
  }, [location.pathname]);

  return (
    <Header
      isMenuOpen={isMenuOpen}
      toggleMenu={toggleMenu}
      scrollActive={scrollActive}
      setIsMenuOpen={setIsMenuOpen}
    />
  );
};

// AppRouter Component
const AppRouter = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollActive, setScrollActive] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const checkScrollPosition = () => {
    setScrollActive(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);
    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <TabProvider>
        <ConditionalHeader
          toggleMenu={toggleMenu}
          scrollActive={scrollActive}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
        <SideMenu toggleMenu={toggleMenu} isOpen={isMenuOpen} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Product />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </TabProvider>
    </Router>
  );
};

export default AppRouter;
