import aboutImageOne from "../assets/ab1.png";
import assuranceIcon from "../assets/assurance-icon.png";
import competitionIcon from "../assets/competition-icon.png";
import reliable from "../assets/reliable.png";
import Container from "../components/Container/Container";
import aboutCoverImage from "../assets/about-cover.png";
import aboutCoverResImage from "../assets/abt-cover-res.jpg";
// import visionMissionBg from "../assets/vision-mission-bg.png";
import missionIcon from "../assets/mission-icon.png";
import visionIcon from "../assets/vision-icon.png";
import teamOne from "../assets/team1.png";
import teamTwo from "../assets/team2.png";
import indiaFlag from "../assets/india-flag.png";

const About = () => {
  return (
    <>
      <Container>
        {/* firt section */}
        <section className="mb-20 lg:mb-32 mt-24  lg:mt-32">
          <div className="w-full pr-10">
            <div className="text-[15px] mb-2 text-green-600 font-medium">
              ABOUT
            </div>
            <h1 className="text-2xl font-semibold lg:w-1/2">
              Comprehensive Building Materials Supply with Unmatched Precision
              and Excellence
            </h1>
          </div>
          <div className="w-full lg:flex mt-10">
            <div className="w-full lg:w-1/2 lg:mr-10">
              <img src={aboutImageOne} alt="materials" className="w-full" />
            </div>
            <div className="mt-8 lg:mt-0 lg:w-1/2">
              <p>
                Seven Elements Trading is a premier supplier of a wide array of
                building materials, serving the diverse needs of the
                construction industry. From TMT Rebars , BRC Mesh, Wood
                materials and Crusher product to Aluminium products , we offer a
                comprehensive selection of materials that meet the highest
                standards of quality and durability.
              </p>
              <br />
              <p>
                Our commitment extends beyond just providing materials; we are
                dedicated to ensuring that every product we deliver contributes
                to the structural integrity and success of your projects. With a
                focus on precision, professionalism, and customer satisfaction,
                we work closely with trusted brands like Muscat Steel, Jindal
                Steel , Oman Cables, SSI Ply woods , Elind , Indal Aluminium and
                various other brands.
              </p>
              <br />
              <p>
                Whether you need specialized products like Crusher Hammer and
                liner plates, or essential construction materials such as
                Aggregates, Wash / Crushed sand Seven Elements Trading is your
                reliable partner in building excellence. We also trade in
                ferrous and non-ferrous scrap materials, offering comprehensive
                solutions for all your building material requirements. We ensure
                that all scrap is collected and the site is kept clear,
                maintaining a clean and efficient working environment.
              </p>
            </div>
          </div>
        </section>

        {/* why choose us */}
        <div className="bg-[#F6F6F6] p-6 lg:p-10">
          <h2 className="text-2xl font-semibold">Why Choose Us?</h2>
          <div className="grid lg:grid-cols-3 gap-5 e py-5">
            {[
              {
                id: 1,
                icon: assuranceIcon,
                title: "Quality Assurance",
                description:
                  "We source our materials from trusted suppliers, ensuring that every product meets industry standards for quality and safety.",
              },
              {
                id: 2,
                icon: competitionIcon,
                title: "Competitive Pricing",
                description:
                  "We offer competitive prices on all our products, providing value without compromising on quality.",
              },
              {
                id: 3,
                icon: reliable,
                title: "Reliable Supply Chain",
                description:
                  "With a robust logistics network, we ensure timely delivery of materials to your construction site, keeping your projects on schedule.",
              },
            ].map((item) => {
              const { id, icon, title, description } = item;
              return (
                <div key={id} className="rounded-md bg-white p-5">
                  <img
                    src={icon}
                    alt={`icon of ` + title}
                    className="w-10 mb-3"
                  />
                  <h3 className="mb-2 text-xl">{title}</h3>
                  <p>{description}</p>
                </div>
              );
            })}
          </div>
        </div>

        {/* about cover */}
        <div className="mt-10">
          <img
            className="w-full hidden lg:block"
            src={aboutCoverImage}
            alt="cover "
          />
          <img
            className="w-full lg:hidden"
            src={aboutCoverResImage}
            alt="cover "
          />
        </div>

        {/* vision mission */}
        <div className="lg:flex gap-5 mt-32">
          <div className="p-6 mb-6 lg:mb-0 lg:p-10 bg-[#F6F6F6] rounded-xl">
            <div className="flex items-center">
              <div className="mr-2 mb-5">
                <img src={missionIcon} alt="mission" className="w-5" />
              </div>
              <div className="text-[15px] mb-2 font-normal">MISSION</div>
            </div>
            <h2 className="text-xl font-semibold mb-5">
              Empowering Construction with Premium Materials and Reliability
            </h2>
            <p>
              Our mission is to provide the construction industry with the
              highest quality building materials, including specialized products
              like TMT Rebars, Jindal Panther, BRC Mesh, Crusher products, Wood
              materials , Aluminium products and more. We are dedicated to
              sourcing and delivering reliable, durable materials that empower
              our clients to build with confidence and precision. Through
              unwavering commitment to excellence and customer satisfaction, we
              strive to be the go-to provider for all your building material
              needs.
            </p>
          </div>
          <div className="p-10 bg-[#F6F6F6] rounded-md">
            <div className="flex items-center">
              <div className="mr-2 mb-5">
                <img src={visionIcon} alt="vision" className="w-5" />
              </div>
              <div className="text-[15px] mb-2 font-normal">VISION</div>
            </div>
            <h2 className="text-xl font-semibold mb-5">
              Leading the Future of Building Materials with Innovation and Trust
            </h2>
            <p>
              Our vision is to be recognized as the leading supplier of building
              materials in the region, known for our extensive range,
              exceptional quality, and innovative solutions. We aim to
              continuously expand our product offerings, maintain the highest
              standards in the industry, and contribute to the success of
              construction projects by being a reliable partner that builders
              and developers can trust.
            </p>
          </div>
        </div>

        <section id="team" className="my-14">
          <div className="w-full pr-10">
            <div className="text-[15px] mb-2 text-green-600 font-medium">
              BUILDING SUCCESS
            </div>
            <h1 className="text-2xl font-semibold lg:w-1/2">
              Seven Elements Trading's Commitment to Quality and Client
              Relationships
            </h1>
          </div>

          <div className="grid md:grid-cols-2 md:gap-8 mt-10">
            <div className="mb-10 md:mb-0">
              <div className="flex mb-5">
                <div className="border-r-2 border-green-600 pr-5">
                  <img src={teamOne} alt="Gheevarghese PV" />
                </div>
                <div className="pl-5 flex flex-col justify-center ">
                  <div className="font-medium">Gheevarghese PV</div>
                  <span>Our Patron</span>
                </div>
              </div>
              <div>
                Seven Elements Trading exhibit correct platform for
                constructional material supplies. We are committed to steadfast
                profitable growth ahead. Thank you our valuable clients for
                supporting a dream
              </div>
            </div>
            <div>
              <div className="flex mb-5">
                <div className="border-r-2 border-green-600 pr-5">
                  <img src={teamTwo} alt="Sigi Joseph" />
                </div>
                <div className="pl-5 flex flex-col justify-center ">
                  <div className="font-medium">Sigi Joseph</div>
                  <span>Share Holder - 7 Elements Trading LLC</span>
                </div>
              </div>
              <div>
                Being a customer centric firm , we focus on sustained effort of
                building long term relationship with our client and ensuring
                maximum time based services
              </div>
            </div>
          </div>
        </section>
      </Container>
      <section className=" bg-[#00110F] py-10 text-white">
        <Container className="grid md:grid-cols-2">
          <div className="mb-5 md:mb-0">
            <img src={indiaFlag} />
            <h3 className="text-2xl">
              Our exporting firm{" "}
              <b className="text-[#E8A454]">SEEJ LEO TRADING LLP</b>
            </h3>
          </div>
          <div>
            SEEJ LEO TRADING LLP is our partner in exporting, committed to
            delivering high-quality products and exceptional service. Our team
            ensures reliable, timely shipments, building strong partnerships.
          </div>
        </Container>
      </section>
    </>
  );
};

export default About;
