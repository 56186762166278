import Container from "../components/Container/Container";
import Tabs from "../components/Tabs/Tabs";
import steelMesh from "../assets/steel-mesh.png";
import tmtRebars from "../assets/tmt-rebar.png";
import bindingWires from "../assets/binding-wire.png";
import polytheneSheets from "../assets/polythene.png";
import plywood from "../assets/plywood.png";
import whiteWood from "../assets/whitewood.png";
import cableWire from "../assets/cables.png";
import utensils from "../assets/utensils.png";
import aluminiumProfile from "../assets/aluminium-profile.png";
import fryingPan from "../assets/frying-pot.png";
import crusher from "../assets/crusher-hammer.png";
import linearPlats from "../assets/linear-plates.png";
import agreeGates from "../assets/aggregates.png";
import washSand from "../assets/washsand.png";
import backFiling from "../assets/blackfiling.png";

const Product = () => {
  const Card = (prop) => {
    return (
      <div className="bg-gray-100 rounded-md p-4 lg:p-5">
        <div className="w-full lg:h-[250px] flex items-center justify-center ">
          <img className="lg:w-[70%]" src={prop.thumbnail} alt="{prop.title}" />
        </div>
        <div className="mt-5">
          <h2>{prop.title}</h2>
          <span className="text-[12px]">
            {prop.isOrgin ? "Orgin : " : "Brand : "} {prop.brand}
          </span>
        </div>
      </div>
    );
  };
  const data = [
    {
      id: 1,
      buttonContent: "Building Material Products",
      body: (
        <>
          <Card
            title="TMT Rebars"
            brand="MUSCAT STEEL & JINDAL PANTHER"
            thumbnail={tmtRebars}
          />
          <Card
            title="Steel BRC Mesh"
            brand="MUSCAT STEEL"
            thumbnail={steelMesh}
          />

          <Card
            title="Binding Wire"
            brand="MUSCAT STEEL"
            thumbnail={bindingWires}
          />
          <Card
            title="Polythene Sheets"
            brand="SMBM"
            thumbnail={polytheneSheets}
          />
          <Card title="Plywoods" brand="SSI" thumbnail={plywood} />
          <Card
            title="White Wood"
            brand="Chile, Germany, Romania"
            isOrgin={true}
            thumbnail={whiteWood}
          />
          <Card
            title="Cables & Wires"
            brand="OMAN CABLES"
            thumbnail={cableWire}
          />
        </>
      ),
    },
    {
      id: 2,
      buttonContent: "Aluminium Products",
      body: (
        <>
          <Card title="Utensils" brand="Elind, Deepam" thumbnail={utensils} />
          <Card
            title="Aluminium Profiles"
            brand="Indal"
            thumbnail={aluminiumProfile}
          />
          <Card title="Frying Pot" brand="Elind" thumbnail={fryingPan} />
        </>
      ),
    },
    {
      id: 3,
      buttonContent: "Steel Casting For Crusher Segments",
      body: (
        <>
          <Card
            title="Crusher Hammer"
            brand="Based on Customized Machine"
            thumbnail={crusher}
          />
          <Card
            title="Linear Plate"
            brand="Various Brands"
            thumbnail={linearPlats}
          />
        </>
      ),
    },
    {
      id: 4,
      buttonContent: "Supplier of Crusher Products",
      body: (
        <>
          <Card
            title="Aggregates"
            brand="Various Crushers"
            thumbnail={agreeGates}
          />
          <Card
            title="Wash Sand/Crushed Sand"
            brand="Various Crushers"
            thumbnail={washSand}
          />
          <Card
            title="Black Filing Materials"
            brand="Various Crushers"
            thumbnail={backFiling}
          />
        </>
      ),
    },
  ];

  return (
    <Container>
      <div className="w-full pr-10 mt-24  lg:mt-32  mb-10">
        <div className="text-[15px] mb-2 text-green-600 font-medium ">
          PRODUCTS
        </div>
        <h1 className="text-2xl font-semibold lg:w-1/2">
          Diverse Building Materials and Specialized Products
        </h1>
      </div>
      <section className="flex">
        <Tabs data={data} />
      </section>
    </Container>
  );
};

export default Product;
