import { Link } from "react-router-dom";
import Container from "../Container/Container";
import facebookIcon from "../../assets/facebook.png";
import twitterIcon from "../../assets/twitter.png";
import instagramIcon from "../../assets/instagram.png";
import { useTab } from "../../context/TabContext";
import logo from "../../assets/LOGO.png";

const Li = ({ children }) => {
  return <li className="text-sm mb-1 text-gray-300">{children}</li>;
};
const Footer = () => {
  const { activeTab, setActiveTab } = useTab();
  return (
    <footer className="bg-black py-12 text-white">
      <Container>
        <div className="grid grid-cols-1 gap-0 gap-y-10 md:grid-cols-2 lg:grid-cols-5 md:gap-2">
          <div>
            <Link to="/" className="logo text-white">
              {/* <div className="font-semibold text-xl mb-1">
                Seven Elements Trading LLC
              </div>
              <div className="text-[12px]"> STEP IN - TRADE SMART</div> */}
              <img src={logo} alt="logo" width={200} />
            </Link>
          </div>
          <div>
            <h3 className="text-xl mb-3 ">Quick Link</h3>
            <ul>
              <Li>
                <Link to="/">Home</Link>
              </Li>
              <Li>
                <Link to="/about">About</Link>
              </Li>
              <Li>
                <Link to="/products">Products</Link>
              </Li>
              <Li>
                <Link to="/contact">Contact</Link>
              </Li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl mb-3">Quick Link</h3>
            <ul>
              <Li>
                <Link onClick={() => setActiveTab(1)} to="/products">
                  Building Material Products
                </Link>
              </Li>
              <Li>
                <Link onClick={() => setActiveTab(2)} to="/products">
                  Aluminium Products
                </Link>
              </Li>
              <Li>
                <Link onClick={() => setActiveTab(3)} to="/products">
                  Steel Casting For Curshers Segments
                </Link>
              </Li>
              <Li>
                <Link onClick={() => setActiveTab(4)} to="/products">
                  Supply of Crusher Products
                </Link>
              </Li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl mb-3">Address</h3>
            <p className="text-sm mb-1 text-gray-300">
              Postal code 111, PO Box 112 Muscat Sultanate of Oman
            </p>
          </div>
          <div>
            <h3 className="text-xl mb-3">Connect Us</h3>
            <ul className="flex">
              <Li>
                <a href="">
                  <img
                    className="w-5 mr-3"
                    src={facebookIcon}
                    alt="facebook-icon"
                  />
                </a>
              </Li>
              <Li>
                <a href="">
                  <img
                    className="w-5 mr-3"
                    src={instagramIcon}
                    alt="instagram-icon"
                  />
                </a>
              </Li>
              <Li>
                <a href="">
                  <img className="w-5 mr-3" src={twitterIcon} alt="x-icon" />
                </a>
              </Li>
            </ul>
          </div>
        </div>
        <div className="md:text-center py-8 text-sm text-gray-200">
          {" "}
          2024 All rights reserved. Copyright Seven Elements Trading LLC |
          Designed by{" "}
          <a
            href="https://webkyat.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple-500 cursor-pointer"
          >
            Webkyat
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
