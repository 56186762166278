import "./App.css";
import AppRouter from "./routes/router";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import favIcon from "./assets/favicon.png";

function App() {
  return (
    <div className="App">
      <FloatingWhatsApp
        phoneNumber="+96898802367"
        accountName="Seven Elements"
        onSubmit={() => console.log("Submitted")}
        avatar={favIcon}
        darkMode={true}
      />
      <AppRouter />
    </div>
  );
}

export default App;
