import { Link } from "react-router-dom";
import Container from "../components/Container/Container";
import bannerImageOne from "../assets/about-image-1.png";
import bannerImageTwo from "../assets/about-image-2.png";
import p1 from "../assets/p1.png";
import commitmentImage from "../assets/commitment.png";
import quoteIcon from "../assets/quoteicon.png";
import mainBannerImage from "../assets/banner-image.png";
import arrow from "../assets/arrow.png";
import arrowClr from "../assets/clr-arrow.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTab } from "../context/TabContext";
import buildingMaterialImage from "../assets/building-materials.png";
import aluminiumProductImage from "../assets/aluminium-products.png";
import steelCastingImage from "../assets/steel-casting-for-crusher-segment.png";
import supplyOfCrusherImage from "../assets/supply-of-crusher-products.png";
import bannerShape from "../assets/shape-banner.png";

function Testimonials() {
  var settings = {
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    prevArrow: null,
    nextArrow: null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div className="px-3">
        <img src={quoteIcon} className="w-ful" alt="quote icon" />
        <p className="italic">
          We have been dealing with seven elements because of high quality &
          reliability of products.we are indeed happy to continue our
          association with them.
        </p>
        <p>Nishanth Sam</p>
        <span>Radco Co</span>
      </div>
      <div className="px-3">
        <img src={quoteIcon} className="w-ful" alt="quote icon" />
        <p className="italic">
          They have wide variety of building materials,wood,Aluminium products
          which gives us choice and helps us to choose the best.
        </p>
        <p>Amir K</p>
        <span>Purchaser</span>
      </div>
      <div className="px-3">
        <img src={quoteIcon} className="w-ful" alt="quote icon" />
        <p className="italic">
          Seven Elements has been my trusted partners for many commercial
          projects their products are competitive in market.
        </p>
        <p>Naser Al Salmi</p>
        <span>Radco Co</span>
      </div>
      <div className="px-3">
        <img src={quoteIcon} className="w-ful" alt="quote icon" />
        <p className="italic">
          I am happy in dealing with the staff of seven elements from quotation
          to material supply, well co ordination and professionalism in each
          levels .
        </p>
        <p>Shanker Palaniswamy</p>
        <span>Amawe</span>
      </div>
    </Slider>
  );
}

const Home = () => {
  const bannerImage = {
    backgroundImage: `url(${mainBannerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const { activeTab, setActiveTab } = useTab();

  const tabHandler = ({ id }) => {
    setActiveTab(id);
  };
  return (
    <>
      {/* hero */}
      <section
        className="h-[80svh] md:h-[100svh] w-full bg-black text-white flex items-center justify-center relative"
        style={bannerImage}
      >
        <div className="absolute right-0 bottom-0 w-full md:hidden">
          <img src={bannerShape} className="w-full" />
        </div>
        <Container>
          <div className="content lg:w-[60%] flex items-start flex-col justify-center ">
            <h1 className="text-4xl md:text-6xl mb-5 font-bold">
              Your Trusted Supplier in Construction Materials{" "}
            </h1>
            <p className="md:text-xl">
              Count on Seven Elements Trading to deliver the highest quality
              building materials, ensuring your projects are built on a
              foundation of trust and excellence.
            </p>
            <Link
              to="/products"
              className="mt-4 flex items-center bg-green-600 rounded-full justify-between  px-2 text-white py-2"
            >
              View Products <img className="w-8 ml-3" src={arrow} />
            </Link>
          </div>
        </Container>
      </section>

      <Container>
        {/* about */}
        <section className=" pb-10 md:py-10 ">
          <div className="md:flex lg:flex">
            <div className="md:w-1/2 lg:w-1/2 pr-10">
              <div className="text-[15px] mb-2  text-green-600 font-medium">
                ABOUT
              </div>
              <h2 className="font-semibold text-2xl mb-8 lg:mb-0">
                Comprehensive Building Materials Supply with Unmatched Precision
                and Excellence
              </h2>
            </div>
            <div className="md:w-1/2 lg:w-1/2">
              <p>
                Seven Elements Trading is a premier supplier of a wide array of
                building materials, serving the diverse needs of the
                construction industry. From TMT Rebars , BRC Mesh, Wood
                materials and Crusher product to Aluminium products , we offer a
                comprehensive selection of materials that meet the highest
                standards of quality and durability.
              </p>

              <Link to="/about">
                <div className="mt-8 ">
                  <div className="py-2 pl-1 w-[150px] flex font-medium text-md  items-center justify-center border-2 border-[#4C9741] rounded-full text-[#4C9741] hover:bg-[#4C9741] hover:text-white">
                    Read More{" "}
                    <img className="w-8 ml-5 rounded-full" src={arrowClr} />
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className=" mt-10 flex-col md:flex-row hidden md:flex">
            <div className="w-full md:w-[68%] pr-10">
              <img src={bannerImageOne} alt="about-image" className="w-full" />
            </div>
            <div className="w-full md:w-[33%]">
              <img src={bannerImageTwo} alt="about-image" className="w-full" />
            </div>
          </div>
        </section>
        {/* products */}
        <div>
          <div className="flex py-10">
            <div className="lg:w-1/2 pr-10">
              <div className="text-[15px] mb-2 text-green-600 font-medium">
                PRODUCTS
              </div>
              <h2 className="font-semibold text-2xl">
                Comprehensive Building Materials Supply with Unmatched Precision
                and Excellence
              </h2>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {[
              {
                id: 1,
                title: "Building Material Products",
                source: buildingMaterialImage,
              },
              {
                id: 2,
                title: "Aluminium Products",
                source: aluminiumProductImage,
              },
              {
                id: 3,
                title: "Steel Casting For Crusher Segments",
                source: steelCastingImage,
              },
              {
                id: 4,
                title: "Supply Of Crusher Products",
                source: supplyOfCrusherImage,
              },
            ].map((item) => (
              <Link
                to="/products"
                className="rounded-xl overflow-hidden relative after:contents['']  "
                key={item.id}
                onClick={() => setActiveTab(item.id)}
              >
                <div className="bg-gradient-to-t from-black to-transparent absolute left-0 top-0 w-full h-full z-0"></div>
                <img src={item.source} alt="about-image" className="w-full" />
                <div className="absolute left-0 bottom-0 p-5 z-1 text-white">
                  <span className="uppercase">Categroy</span>
                  <h3 className="text-xl">{item.title}</h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
        {/* commitments */}
        <div>
          <div className="lg:flex py-10">
            <div className="lg:w-1/2 pr-10">
              <div className="text-[15px] mb-2 text-green-600 font-medium">
                WHY US
              </div>
              <h2 className="font-semibold text-2xl">
                Comprehensive Building Materials Supply with Unmatched Precision
                and Excellence
              </h2>
            </div>
          </div>
          <div className="lg:flex">
            <div className="w-full lg:w-1/2">
              <img className="w-full" src={commitmentImage} alt="about-image" />
            </div>
            <div className="mt-8 lg:w-1/2 lg:px-10 flex items-center">
              <ul>
                {[
                  {
                    id: 1,
                    title: "Uncompromising Quality",
                    description:
                      "We provide the highest quality products, including TMT Rebars and Aluminium profiles, from top brands, ensuring that your projects are built to last.",
                  },
                  {
                    id: 2,
                    title: "Comprehensive Selection",
                    description:
                      "We offer a comprehensive selection of materials that meet the highest standards of quality and durability.",
                  },
                  {
                    id: 3,
                    title: "Competitive Pricing",
                    description:
                      "We offer competitive pricing for our products, ensuring that you receive the best value for your money.",
                  },
                  {
                    id: 4,
                    title: "Timely Delivery",
                    description:
                      "We provide timely delivery of our products to ensure that you receive your order on time.",
                  },
                ].map((item) => (
                  <li className="flex justify-between mb-10" key={item.id}>
                    <div className="font-semibold bg-green-200 h-10 w-10 rounded-full flex items-center justify-center ">
                      0{item.id}
                    </div>
                    <div style={{ width: "calc(100% - 60px)" }}>
                      <h3 className="text-xl font-semibold mb-2">
                        {item.title}
                      </h3>
                      <p>{item.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>

      {/* testimonials */}
      <div className="bg-[#00110F] text-white py-10 lg:py-32 mt-20">
        <Container>
          <div>
            <div className="lg:w-1/2 pr-10 mb-10">
              <div className="text-[15px] mb-2 text-green-600 font-medium">
                TESTIMONIALS
              </div>
              <h2 className="font-semibold text-2xl">What Our Clients Say</h2>
            </div>
            <Testimonials />
          </div>
        </Container>
      </div>
    </>
  );
};
export default Home;
