import { Link, useLocation } from "react-router-dom";
import Container from "../Container/Container";
import logo from "../../assets/LOGO.png";

const Header = (prop) => {
  const location = useLocation().pathname;
  return (
    <header className="fixed  top-0 left-0 w-full z-20 backdrop-blur-sm bg-[#00110F]">
      <Container className="flex items-center justify-between h-full px-4">
        <Link
          to="/"
          onClick={() => prop.setIsMenuOpen(false)}
          className="logo text-white"
        >
          {/* <div className="font-semibold text-[20px]  ">
            Seven Elements Trading LLC
          </div>
          <div className="text-[12px] text-green-500">
            {" "}
            STEP IN - TRADE SMART
          </div> */}
          <img src={logo} alt="logo" width={200} />
        </Link>
        <nav className="hidden md:block">
          <ul className="flex">
            <li className="text-white mr-10">
              <Link to="/" className={location === "/" ? "text-red-500" : ""}>
                Home
              </Link>
            </li>
            <li className="text-white mr-10">
              <Link
                to="/about"
                className={location === "/about" ? "text-red-500" : ""}
              >
                About
              </Link>
            </li>
            <li className="text-white mr-10">
              <Link
                to="/products"
                className={location === "/products" ? "text-red-500" : ""}
              >
                Product
              </Link>
            </li>
            <li className="text-white mr-10">
              <Link
                to="/contact"
                className={location === "/contact" ? "text-red-500" : ""}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <div
          className="text-white block md:hidden relative right-2 top-1"
          onClick={prop.toggleMenu}
        >
          {prop.isMenuOpen ? (
            <i className="fi fi-rr-cross text-xl"></i>
          ) : (
            <i className="fi fi-rr-apps text-2xl"></i>
          )}
        </div>
      </Container>
    </header>
  );
};

export default Header;
